import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import { toast } from "react-toastify";


const Contact = () => {
    const [inputValues, setInputValues] = useState({ name: "", email: "", message: "", subject: "" });
    const [disable, setDisabled] = useState(false);
    
    const [fewStepsData, setFewStepsData] = useState();

    const handleInputValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputValues({ ...inputValues, [name]: value })
    }

    const getFewStepsData = async () => {
        try {
            const res = await axios.get("https://www.cashknock.in/admin/api/mgetAllFewStep")
            setFewStepsData(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setDisabled(true)
            const res = await axios.post("https://cashknock.in/admin/api/contactSave", inputValues);
            setInputValues({ name: "", email: "", message: "", subject: "" })
            setDisabled(false)
            if (res.data.status == 1) {
                toast.success("We've received your request,we will contact you soon.");
            } else {
                toast.error("Unable to send request :(");
            }
            console.log(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (inputValues.name.length > 0 && inputValues.email.length && inputValues.subject.length && inputValues.message.length) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [inputValues]);

    useEffect(() => {
        getFewStepsData();
    }, [])


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Cashknock - Contact
                    </title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" class="breadcrumbs">
                    <div class="container">
                        <div class="breadcrumb-bg">
                            <h2>Connect With Us</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Contact Us</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section id="contact" class="contact">
                    <div class="container">

                        <div class="section-title">
                            <h2 data-aos="fade-up">Contact</h2>
                            <p data-aos="fade-up">Please fill the form below to get instant response to your query</p>
                        </div>

                        <div class="row justify-content-center">

                            <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
                                <div class="info-box">
                                    <i class="bx bx-map"></i>
                                    <h3>Our Address</h3>
                                    <p> D-54, Second Floor, Sector - 2, Noida</p>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
                                <div class="info-box">
                                    <i class="bx bx-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>support@cashknock.in</p><br />
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
                                <div class="info-box">
                                    <i class="bx bx-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <p>+91 8800390953</p><br />
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="300">
                            <div class="col-xl-9 col-lg-12 mt-4">
                                <form class="php-email-form">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" onChange={handleInputValues} value={inputValues.name} required />
                                        </div>
                                        <div class="col-md-6 form-group mt-3 mt-md-0">
                                            <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" onChange={handleInputValues} value={inputValues.email} required />
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleInputValues} value={inputValues.subject} required />
                                    </div>
                                    <div class="form-group mt-3">
                                        <textarea class="form-control" name="message" rows="5" placeholder="Message" onChange={handleInputValues} value={inputValues.message} required ></textarea>
                                    </div>
                                    <div class="my-3">
                                        <div class="loading">Loading</div>
                                        <div class="error-message"></div>
                                        <div class="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div class="text-center">
                                        {
                                            disable ? <button type="submit" className="pe-none opacity-75" onClick={handleSubmit}>Send Message</button> : <button type="submit" onClick={handleSubmit}>Send Message</button>
                                        }

                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </section>



                <section id="services" class="services section-bg-2">
                    <div class="container">

                        <div class="section-title text-white" data-aos="fade-up">
                            <h2 class="text-white">Just Few Steps To Sell Your Phone</h2>
                            <p>Book a free pickup from your home or work at a time slot as per your convenience</p>
                        </div>
                        <div class="row">

                            {
                                fewStepsData?.map((ele) => (
                                    <div class="col-lg-4 col-md-6" data-aos="fade-up">
                                        <div class="icon-box">
                                            <div class="step-img"><img src={ele.image} alt="cashknock category" />
                                                <div class="c-anim">
                                                    <div class="outerCircle"></div>
                                                    <div class="innerCircle"></div>
                                                    <div class="icon"></div>
                                                </div>
                                            </div>
                                            <h4 class="title"><a href="">{ele.heading}</a></h4>
                                            <p class="description text-white">{ele.content}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Contact;
