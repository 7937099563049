import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button, Form, Input, Card } from 'antd';
import Loader from "../components/Loader"


const ExactValue = () => {
    const [productData, setProductData] = useState(null);
    const [qdata, setQuestionData] = useState([]);
    const [showSubmitButton, setSubmitButton] = useState(true);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [qIndex, setQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [answersOptions, setOptionAnswers] = useState({});
    const [requiredData, setRequiredData] = useState(false);
    const [supportBtn, setSupportBtn] = useState(true);

    const searchParams = useLocation();
    const { state } = searchParams;

    console.log(state);

    const { TextArea } = Input;
    const navigate = useNavigate();



    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        fetchAllQuestions();
    }, []);

    const fetchAllQuestions = async () => {
        try {
            const response = await axios.post('https://cashknock.in/admin/api/getAllQuestion', {
                category_id: state.category_id,
                model_id: state.model_id
            });

            // console.log(response.data);

            if (response.data['status'] === 1) {
                setQuestionData(response.data['data']);
                setProductData(response.data);
                setLoading(false);

            } else {
                setQuestionData([]);
                setLoading(false);
            }

        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const handleAnswerChange = (questionId, answerValue, type, option, question) => {
        submitNewQuestionsAnswers(type, option, question);
        setRequiredData(false);
        setAnswers((prevAnswers) => {
            const newAnswers = { ...prevAnswers };

            // const currentAnswers = [];
            if (type === 3) { // checkbox
                const currentAnswersValue = newAnswers[questionId] ? newAnswers[questionId].split(',') : [];
                const currentAnswersOption = newAnswers[`${questionId}_option`] ? newAnswers[`${questionId}_option`].split(',') : [];

                if (currentAnswersOption.includes(option)) {
                    const optionIndex = currentAnswersOption.indexOf(option);
                    newAnswers[questionId] = currentAnswersValue.filter((ans, ind) => ind !== optionIndex).join(',');
                    newAnswers[`${questionId}_option`] = currentAnswersOption.filter((ans, ind) => ind !== optionIndex).join(',');
                } else {
                    newAnswers[questionId] = [...currentAnswersValue, answerValue].join(',');
                    newAnswers[`${questionId}_option`] = [...currentAnswersOption, option].join(',');
                }
            } else { // radio
                newAnswers[questionId] = answerValue;
                newAnswers[`${questionId}_option`] = option;
            }

            // Update option and question
            newAnswers[`${questionId}_question`] = question;
            return newAnswers;
        });
    };

    const submitNewQuestionsAnswers = (type, answerValue, questionId) => {
        // console.log("newoptionsvalues:" + answerValue + "=====" + questionId);

        setOptionAnswers((prevOpAnswers) => {
            const newOptionAnswers = { ...prevOpAnswers };

            if (type === 3) { // checkbox
                const currentOptionAns = newOptionAnswers[questionId] ? newOptionAnswers[questionId].split(',') : [];
                if (currentOptionAns.includes(answerValue)) {
                    newOptionAnswers[questionId] = currentOptionAns.filter(ans => ans !== answerValue).join(',');
                } else {
                    newOptionAnswers[questionId] = [...currentOptionAns, answerValue].join(',');
                }
            } else { // radio
                newOptionAnswers[questionId] = answerValue;
            }

            // Update option and question
            newOptionAnswers[`${questionId}_option`] = answerValue;
            newOptionAnswers[`${questionId}_question`] = questionId;

            return newOptionAnswers;
        });
    };

    const generateSummary = (answersOptions) => {


        const summaryItems = Object.keys(answersOptions)
            .filter(key => !key.includes('_option') && !key.includes('_question'))
            .map((questionId) => {
                const answer = answersOptions[questionId];
                const question = answersOptions[`${questionId}_question`];
                // console.log(answer);
                return (
                    <div key={questionId}>
                        <h4>{question}</h4>
                        <ul>
                            <li>
                                <div className="row">
                                    {/* <div className="col-md-4">Price Value:</div> */}
                                    <div className="col-md-12"><strong>{answer}</strong></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                );
            });

        return (
            <div className="evaluate-summary">
                <h4>Device Evaluation</h4>
                {summaryItems}
            </div>
        );
    };

    const handleNextQuestion = async () => {

        let returnFromFunc = false;
        qdata[qIndex].questions.forEach(element => {
            if (!answers[`${element.id}_option`] || answers[`${element.id}_option`] === '') {
                setRequiredData(true);
                returnFromFunc = true;
                return;
            }
        });

        if (returnFromFunc) {
            return;
        }

        //scroll to Top while changing questions
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })

        let user_id = localStorage.getItem('user_id');
        if (!user_id) {
            const modalElement = document.querySelector('#loginModel');
            const loginModal = new window.bootstrap.Modal(modalElement);
            loginModal.show();
            return;
        }

        // Assuming qIndex is the current question index
        const currentQuestion = qdata[qIndex]; // Assuming qdata is your array of questions
        const optionForCurrentQuestion = answers[`${currentQuestion.questions[qIndex]?.id}_option`];
        // Check if it's the first question and answer is "no"
        if (qIndex === 0 && optionForCurrentQuestion && optionForCurrentQuestion.toLowerCase() === 'no') {
            handleSubmit(); // Directly submit if the option is "no" for the first question
            return;
        }
        // Proceed to the next question normally
        if (qIndex < qdata.length - 1) {
            setSubmitButton(true);
            setQuestionIndex(qIndex + 1);

        } else {
            setSubmitButton(false);
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        const user_id = localStorage.getItem('user_id');
        const variant_id = localStorage.getItem('product_variant');

        const jsonToSubmit = {
            user_id: parseInt(user_id, 10),
            model_id: parseInt(state.model_id, 10),
            variant_id: parseInt(variant_id, 10),
            questions: qdata.flatMap(q_item => {
                return q_item.questions.map(question => {
                    const questionId = question.id;
                    const questionType = parseInt(question.type, 10);
                    let answerValue = answers[questionId];
                    let answerId = [];

                    if (questionType === 3) { // checkbox
                        answerId = (answerValue?.split(',').map(value => {
                            const answer = question.answers.find(ans => ans.value === value);
                            return answer ? answer.id : null;
                        }).filter(id => id !== null)) || [];
                        answerValue = answerValue ? answerValue.split(',') : [];
                    } else { // other types
                        const answer = question.answers.find(ans => ans.value === answerValue);
                        answerId = answer ? [answer.id] : [];
                        answerValue = answerValue ? [answerValue] : [];
                    }

                    return {
                        step_id: q_item.level_no,
                        question_id: questionId,
                        question_type: questionType,
                        answer_id: answerId,
                        answer_value: answerValue
                    };
                });
            })
        };

        try {
            console.log(jsonToSubmit);
            const response = await axios.post('https://cashknock.in/admin/api/storeAnswer2', jsonToSubmit);
            localStorage.setItem('order-details', JSON.stringify(response.data.data));
            toast.success("Data submitted successfully");
            const params = {
                productimage: productData.productimage, category_id: state.category_id,
                model_id: state.model_id,category_name:state.category_name
            }
            navigate(`/sell-quote-details`, {
                state: params,
                replace: true
            });
        } catch (error) {
            toast.error("Failed to submit data");
        }
    };

    const onFinish = (values) => {
        sendSupportMail(values);
    };

    const sendSupportMail = async (values) => {
        try {
            setSupportBtn(false);
            console.log(values);
            console.log(state.category_id)
            console.log(state.model_id)
            const response = await axios.post('https://cashknock.in/admin/api/productsupport', {
                name: values.name,
                email: values.email,
                subject: values.subject,
                content: values.content,
                category_id: state.category_id,
                product_id: state.model_id,
            });
            setSupportBtn(true);
            if (response.data.status == 1) {
                toast.success("We have received your query we will contact you soon.");
                navigate('/');
            } else if (response.data.errors) {
                toast.error(response.data.errors.email[0]);
            } else {
                toast.error(response.data.message);
            }

            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />;
    }


    return (
        <main id="main">
            <div id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb-bg">
                        <h2>{productData?.productname}</h2>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li>{state.category_name}</li>
                            <li>{productData?.productname}</li>
                        </ol>
                    </div>
                </div>
            </div>

            {
                qdata && qdata.length > 0 ?
                    <section id="device-detail" className="device-detail">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 col-md-10 col-10">
                                    <div className="section-title">
                                        <h2 data-aos="fade-up">{qdata[qIndex].heading}</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-8 col-md-7 col-12">
                                    <div className="evaluate-details">

                                        {qdata[qIndex].questions.map((q_item) => (
                                            <div className="row" key={q_item.id}>
                                                {/* {parseInt(q_item.type, 10) === 2 && (
                                                    <p className="question">{q_item.question}</p>
                                                )} */}

                                                <p className="question">Q. {q_item.question}</p>
                                                {q_item.answers.map((answer) => (
                                                    parseInt(q_item.type, 10) === 3
                                                        ?
                                                        (
                                                            <div className="col-lg-3 col-6" key={answer.id}>
                                                                <div className="ec-box ec-box-height">
                                                                    <input
                                                                        type="checkbox"
                                                                        name={`question-${q_item.id}`}
                                                                        value={answer.value}

                                                                        // checked={answers[q_item.id]?.split(',').includes(answer.value) || false}

                                                                        onChange={() => handleAnswerChange(q_item.id, answer.value, parseInt(q_item.type, 10), answer.option, q_item.question)}
                                                                        className="checkboxstyle"
                                                                        id={`question-${answer.id}`}
                                                                    />
                                                                    <div className="checked-style"></div>
                                                                    <label htmlFor={`question-${answer.id}`}>
                                                                        <img src={answer.image} alt="cashknock" />
                                                                        &nbsp;
                                                                        <div className="ec-box-paradiv">
                                                                            <p>{answer.option}</p>
                                                                        </div>
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        parseInt(q_item.type, 10) === 1
                                                            ?
                                                            (
                                                                <div className="col-lg-3  col-6" key={answer.id}>
                                                                    {/* {console.log(answer.image)} */}
                                                                    {
                                                                        answer.image ? <div className="ec-box ec-box-height">
                                                                            <input
                                                                                type="radio"
                                                                                name={`question-${q_item.id}`}
                                                                                value={answer.value}
                                                                                onChange={() => handleAnswerChange(q_item.id, answer.value, parseInt(q_item.type, 10), answer.option, q_item.question)}
                                                                                className="checkboxstyle"
                                                                                id={`question-${answer.id}`}
                                                                            />
                                                                            <div className="checked-style"></div>
                                                                            <label htmlFor={`question-${answer.id}`}>
                                                                                <img src={answer.image} alt="cashknock" />
                                                                                &nbsp;
                                                                                <div className="ec-box-paradiv">
                                                                                    <p>{answer.option}</p>
                                                                                </div>
                                                                            </label>

                                                                        </div> : <div className="ec-box ec-box-height">
                                                                            <input
                                                                                type="radio"
                                                                                name={`question-${q_item.id}`}
                                                                                value={answer.value}
                                                                                onChange={() => handleAnswerChange(q_item.id, answer.value, parseInt(q_item.type, 10), answer.option, q_item.question)}
                                                                                className="checkboxstyle"
                                                                                id={`question-${answer.id}`}
                                                                            />
                                                                            <div className="checked-style checked-style-image-not"></div>
                                                                            <label htmlFor={`question-${answer.id}`}>

                                                                                <div className="ec-box-paradiv">
                                                                                    <p style={{ marginBottom: "20px" }}>{answer.option}</p>
                                                                                </div>
                                                                            </label>

                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <div className="col-lg-3  col-6" key={answer.id}>
                                                                    <div className="ec-box">
                                                                        <label className="radio option-box">
                                                                            <input
                                                                                type={q_item.type === "3" ? "checkbox" : "radio"}
                                                                                name={`question-${q_item.id}`}
                                                                                value={answer.value}
                                                                                onChange={() => handleAnswerChange(q_item.id, answer.value, parseInt(q_item.type, 10), answer.option, q_item.question)}
                                                                            />
                                                                            &nbsp;
                                                                            <span>{answer.option.charAt(0).toUpperCase() + answer.option.slice(1)}</span>
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            )
                                                ))}
                                            </div>
                                        ))}


                                        {showSubmitButton == true
                                            ?
                                            <>
                                                {
                                                    requiredData
                                                        ?
                                                        <>
                                                            <h6 style={{ color: "red" }}>Select at least one option*</h6>
                                                            <button
                                                                type="button"
                                                                className="continue-btn pe-none opacity-75"
                                                                id="continue1"
                                                                onClick={handleNextQuestion}
                                                            >
                                                                {qIndex < qdata.length - 1 ? "Continue" : "Submit"}
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="continue-btn"
                                                                id="continue1"
                                                                onClick={handleNextQuestion}
                                                            >
                                                                {qIndex < qdata.length - 1 ? "Continue" : "Submit"}
                                                            </button>
                                                        </>
                                                }
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>



                                <div className="col-lg-4 col-md-5 col-12">
                                    <div className="evaluate-sidebar">
                                        {productData && (
                                            <>
                                                <div className="d-title d-flex align-items-center">
                                                    <img src={productData.productimage} alt="cashknock" />
                                                    <h2>{productData?.productname}</h2>
                                                </div>
                                                {generateSummary(answersOptions)}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section id="contact" class="contact">
                        <div class="container">

                            <div class="section-title">
                                <h2 data-aos="fade-up">Support</h2>
                                <p data-aos="fade-up">Please fill the form below to get instant response to your query</p>
                            </div>



                            <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="300">

                                <div class="col-xl-9 col-lg-12 mt-4">

                                    <Form layout="horizontal" size="large" onFinish={onFinish} role="form" class="php-email-form">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <Form.Item
                                                    name="name"
                                                    rules={[{ required: true, message: 'Please input your name!' }]}
                                                >
                                                    <Input placeholder="Your Name" />
                                                </Form.Item>
                                            </div>
                                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                                <Form.Item
                                                    name="email"
                                                    rules={[{ required: true, email: true, message: 'Please input your email!' }]}
                                                >
                                                    <Input placeholder="Your Email" />
                                                </Form.Item>
                                            </div>


                                            <div class="form-group mt-3">
                                                <Form.Item
                                                    name="subject"
                                                    rules={[{ required: true, message: 'Please input your Subject!' }]}
                                                >
                                                    <Input placeholder="Subject" />
                                                </Form.Item>
                                            </div>
                                            <div class="form-group mt-3">
                                                <Form.Item
                                                    name="content"
                                                    rules={[{ required: true, message: 'Please input your content!' }]}
                                                >
                                                    <TextArea rows={4} placeholder="Your Content" />
                                                </Form.Item>

                                            </div>
                                        </div>


                                        <Form.Item>
                                            <div class="text-center">
                                                {
                                                    supportBtn ? <Button type="primary" className="continue-btn" htmlType="submit">
                                                        Send Message
                                                    </Button> : <>

                                                        <Button type="primary" className="continue-btn" htmlType="submit" style={{ pointerEvents: "none", opacity: "0.9" }}>
                                                            Sending...
                                                        </Button></>
                                                }
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </div>

                            </div>

                        </div>
                    </section>

            }
        </main>
    );
};

export default ExactValue;
