import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Loader from "../components/Loader";

const Terms = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://cashknock.in/admin/api/getAllTermCondition');
                setLoading(false);
                if (isMounted) {
                    console.log(response.data);
                    setData(response.data.data);
                    console.log("hello");
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                    console.log("namstey..");
                }
            }
        };

        fetchData();
        // Cleanup function (optional)
        return () => {
            isMounted = false; // Mark the component as unmounted
        };
    }, []);

    if (loading) {
        return <Loader />
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - Terms and Conditions</title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" class="breadcrumbs">
                    <div class="container">
                        <div class="breadcrumb-bg">
                            <h2>Terms & Conditions</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Terms Of Service</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section id="terms" class="terms">
                    <div class="container">
                        <div class="section-title">
                            <h2 >Our Terms and Conditions</h2>
                            <p ><strong>Please read carefully these “terms of use” before using this website “Cashknock”.</strong></p>
                        </div>

                        <div class="detail-child" style={{ textAlign: 'left' }}>
                            {data && data.map(option => (
                                <>

                                    <h4>{option.heading}</h4>
                                    <span class="MuiTypography-root MuiTypography-p terms-p css-1uk1gs8"><div className="tnc-content" dangerouslySetInnerHTML={{ __html: option.content }}></div></span>
                                    {/* <h4>Credit Reporting Terms of Service</h4> */}
                                </>
                            ))}
                            {/* <h4>Legal Disclaimer:</h4>
                        <span class="MuiTypography-root MuiTypography-p terms-p css-1uk1gs8">We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, company name. We will collect personal identification information from Users only if they voluntarily consent such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</span>
                        <h4>Credit Reporting Terms of Service</h4>
                        <span class="MuiTypography-root MuiTypography-p terms-p css-1uk1gs8">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum, quod. Ratione ex delectus quis tenetur odio non alias numquam official ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, laborum.</span><span class="MuiTypography-root MuiTypography-p terms-p css-1uk1gs8">We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, company name. We will collect personal identification information from Users only if they voluntarily consent such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</span>
                        <ol sx="[object Object]">
                            <li>Mauris ut in vestibulum hasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis.</li>
                            <li>Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis justo venenatis amet.</li>
                            <li>Lectus quam there are two thing is very important in Consectetur phasellus ultrices fusce nibh justo, venenatis, amet in lobortis.</li>
                            <li>Web Development very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet to all design.</li>
                        </ol>
                        <h4>Ownership of Site Agreement to Terms of Use</h4>
                        <span class="MuiTypography-root MuiTypography-p terms-p css-1uk1gs8">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.</span><span class="MuiTypography-root MuiTypography-p terms-p css-1uk1gs8">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil eveniet quas dignissimos doloribus ea pariatur corrupti rerum deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero neque reiciendis cupiditate dolores nam. Earum eius similique sapiente. Iure, sit non. At fuga ipsam veniam.</span> */}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default Terms