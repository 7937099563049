import axios from 'axios';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';

const BlogDetails = () => {
    const searchParams = useLocation();
    const { pathname } = searchParams;
    const navigate = useNavigate();
    const [blogData, setBlogData] = useState();
    const [latestBlog, setLatestBlog] = useState();
    const [Loading, setLoading] = useState(false);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        getBlogByURL();
        latestBlogData();
    }, [pathname])

    if (Loading) {
        return <Loader />
    }


    const getBlogByURL = async () => {
        try {
            setLoading(true);
            const res = await axios.post("https://cashknock.in/admin/api/getBlogByUrl", {
                url: pathname.split("/")[2]
            })
            if (res.data.status === 1) {
                setBlogData(res.data.data);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const latestBlogData = async () => {
        try {
            const res = await axios.get("https://cashknock.in/admin/api/getLatestBlog")
            if (res.data.status === 1) {
                setLatestBlog(res.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleGetDetail = (url) => {
        const params = { url: url };
        navigate(`/blogs/${url}`, { state: params })
    }


    return (
        <main className=''>
            <div id="breadcrumbs" class="breadcrumbs ">
                <div class="container">
                    <div class="breadcrumb-bg">
                        <h2>Blog Details</h2>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/blogs">Blogs</Link></li>
                            <li>Blog Details</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div class="blog-single">
                <div class="container">
                    {
                        blogData && (
                            <div class="row align-items-start">
                                <div class="col-lg-8 m-15px-tb">
                                    <article class="article">
                                        <div class="article-img">
                                            <img src={blogData.image} title="" alt="" />
                                        </div>
                                        <div class="article-title">
                                            <span><i class="bi bi-calendar-check me-2"></i>{blogData.blog_date}</span>
                                            <span className='ms-2'>created by  - {blogData.blog_by}</span>
                                            <h2>{blogData.heading}</h2>

                                        </div>
                                        <div class="article-content" dangerouslySetInnerHTML={{ __html: blogData.description }}>
                                        </div>

                                    </article>

                                </div>
                                <div class="col-lg-4 m-15px-tb blog-aside">


                                    {/* <!-- Latest Post --> */}
                                    <div class="widget widget-latest-post">
                                        <div class="widget-title">
                                            <h3 style={{ color: 'black' }}>Latest Blogs</h3>
                                        </div>
                                        <div class="widget-body">
                                            {
                                                latestBlog?.map((ele) =>
                                                    <div class="latest-post-aside media">
                                                        <div class="lpa-left media-body">
                                                            <div class="lpa-title">
                                                                <h5><a href="javascript:void(0)" onClick={() => handleGetDetail(ele.url)}>{ele.heading}</a></h5>
                                                            </div>
                                                            <div class="lpa-meta">
                                                                <a class="name" href="javascript:void(0)" onClick={() => handleGetDetail(ele.url)}>
                                                                    {ele.blog_by}
                                                                </a>
                                                                <a class="date" href="javascript:void(0)" onClick={() => handleGetDetail(ele.url)}>
                                                                    {ele.blog_date}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="lpa-right">
                                                            <a href="javascript:void(0)" onClick={() => handleGetDetail(ele.url)}>
                                                                <img src={ele.image} title="" alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>


                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </main>
    )
}

export default BlogDetails