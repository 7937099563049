import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';

const SellQuoteDetails = () => {
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openFaqIndex, setOpenFaqIndex] = useState(null);
    const [canNotSell, setCanNotSell] = useState(false);
    const navigate = useNavigate();

    const moveToCheckout = () => {
        navigate('/checkout', { replace: true });
    }

    const location = useLocation();
    const { state } = location;

    let formattedTotalAmount;

    let storedOrderDetails = JSON.parse(localStorage.getItem('order-details'));
    if (isNaN(storedOrderDetails.totalAmount)) {
        formattedTotalAmount = storedOrderDetails.totalAmount;
    } else {
        formattedTotalAmount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'INR'
        }).format(storedOrderDetails.totalAmount);
    }
    useEffect(() => {
        if (isNaN(storedOrderDetails.totalAmount)) {
            setCanNotSell(true);
        }
    }, [storedOrderDetails])


    useEffect(() => {
        getAllFAQData();
    }, []);

    const getAllFAQData = async () => {
        try {
            const response = await axios.get('https://cashknock.in/admin/api/getAllFAQ');
            setFaqData(response.data.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    const toggleFaq = (index) => {
        setOpenFaqIndex(openFaqIndex === index ? null : index);
    };

    const handleRecalculate = (category_id, model_id,category_name) => {
        const params = { category_id: category_id, model_id: model_id,category_name:category_name };
        navigate("/exact-value", {
            state: params,
            replace: true
        })
    }

    return (
        <main id="main">
            <div id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <div className="breadcrumb-bg">
                        <h2>Sell Quote</h2>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li>Sell Quote</li>
                            <li>Sell Quote Details</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section id="sell-quote" className="sell-quote">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12">
                            <div className="d-detail">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <img src={state.productimage} alt="cashknock" />
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-12">
                                        <div className="section-title">
                                            <h2>{storedOrderDetails.productName} ({storedOrderDetails.variant})</h2>
                                            <p>Selling Price:</p>
                                            <h3>{formattedTotalAmount}</h3>
                                        </div>
                                        <div className="calci mb-5"><a style={{ cursor: "pointer" }} className="calci-btn" onClick={() => handleRecalculate(state.category_id, state.model_id,state.category_name)} >Re-calculate <i className="bx bxs-chevrons-right"></i></a></div>
                                    </div>
                                </div>
                                <div className="quote-feature">
                                    <div className="row mt-4">
                                        <div className="col-lg-3 col-md-6 col-6 mb-3 bord-r">
                                            <div className="y-feature"> <img src="assets/img/why-us/1.png" alt="why-us feature" />
                                                <h4>One-Stop Solution</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6 mb-3 bord-r">
                                            <div className="y-feature"> <img src="assets/img/why-us/2.png" alt="why-us feature" />
                                                <h4>Trained Professionals</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6 mb-3 bord-r">
                                            <div className="y-feature"> <img src="assets/img/why-us/3.png" alt="why-us feature" />
                                                <h4>Amazing Prices</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6 mb-3">
                                            <div className="y-feature"> <img src="assets/img/why-us/4.png" alt="why-us feature" />
                                                <h4>Quick & Hassle-free Payment</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-detail faq section-bg mt-4" id="faq">
                                <h2 className="bord-b mb-4">FAQs</h2>
                                <div className="faq-list px-0">
                                    <ul>
                                        {loading === false && faqData && faqData.map((faq, index) => (
                                            <li key={faq.id}>
                                                <i className="bx bx-help-circle icon-help"></i>
                                                <a
                                                    data-bs-toggle="collapse"
                                                    className={`collapse ${openFaqIndex === index ? 'open' : ''}`}
                                                    data-bs-target={`#faq-list-${faq.id}`}
                                                    onClick={() => toggleFaq(index)}
                                                >
                                                    {faq.question}
                                                    <i className="bx bx-chevron-down icon-show"></i>
                                                    <i className="bx bx-chevron-up icon-close"></i>
                                                </a>
                                                <div
                                                    id={`faq-list-${faq.id}`}
                                                    className={`collapse ${openFaqIndex === index ? 'show' : ''}`}
                                                    data-bs-parent=".faq-list"
                                                >
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 p-summary">
                            <div className="evaluate-sidebar">
                                <div className="d-flex align-items-center bord-b">
                                    <h4>Price Summary</h4>
                                </div>
                                <div className="row mt-3 bord-b">
                                    <div className="col-md-8">
                                        <h6>Base Price</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>{formattedTotalAmount}</h6>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-8">
                                        <h6><strong>Total Amount</strong></h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6><strong>{formattedTotalAmount}</strong></h6>
                                    </div>
                                </div>
                                {
                                    canNotSell ? <div className="col-md-12 text-center mt-3 pe-none opacity-75"> <a className="sum-btn" href="javascript:void(0)" onClick={() => moveToCheckout()}>Sell Now</a>
                                    </div> : <div className="col-md-12 text-center mt-3"> <a className="sum-btn" href="javascript:void(0)" onClick={() => moveToCheckout()}>Sell Now</a>
                                    </div>
                                }
                            </div>
                            {
                                canNotSell ? null : <div className="evaluate-sidebar mt-4">
                                    <div className="payment">
                                        <h4 className="bord-b mb-4">Payment Options</h4>
                                        <ul>
                                            <li><Link to="/checkout"><img src="assets/img/payment/upi.webp" alt="cashknock UPI" /></Link></li>
                                            <li><Link to="/checkout"><i className="bx bxs-wallet"></i> Instant Cash</Link></li>
                                            <li><Link to="/checkout"><i className="bx bxs-bank"></i> Bank Transfer</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default SellQuoteDetails;
