import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Loader from "../components/Loader";

const Privacy = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://cashknock.in/admin/api/getAllPolicy');
                setLoading(false);
                if (isMounted) {
                    setData(response.data.data);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                }
            }
        };

        fetchData();
        // Cleanup function (optional)
        return () => {
            isMounted = false; // Mark the component as unmounted
        };
    }, []);

    if (loading) {
        return <Loader />
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - Privacy</title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" class="breadcrumbs">
                    <div class="container">
                        <div class="breadcrumb-bg">
                            <h2>Privacy Policy</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Privacy Policy</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section id="privacy" class="privacy">
                    <div class="container">
                        <div class="section-title">
                            <h2 >Our Privacy Policy</h2>
                            <p ><strong>Please read carefully these “Privacy Policies” before using this website “Cashknock”.</strong></p>
                        </div>


                        <div class="detail-child" style={{ textAlign: 'left' }}>
                            {data && data.map(option => (
                                <>
                                    <h4>{option.heading}</h4>
                                    <span class="MuiTypography-root MuiTypography-p terms-p css-1uk1gs8">
                                        <div className="privacy-content" dangerouslySetInnerHTML={{ __html: option.content }}></div>
                                    </span>
                                </>
                            ))}

                            {/* <p class="MuiTypography-root MuiTypography-body1 css-9l3uo3"> These terms of use govern your access to and use of the website. The website is available for your use only on the condition that you agree to the terms of use set forth below. If you do not agree to all the terms of use, do not access or use the website. By accessing or using the website you are bound by the terms of use. These terms and conditions have status of legal binding contract between Cashknock and “seller or buyer” of the goods” “Cashknock” which provides an online platform to avail the services of buying and selling of used consumer products. Cashknock acts as a platform to allow sellers or buyers who comply with the policies to sell certain good. Your conduct, your actual sales and other transactions are directly with the Cashknock. Therefore, you are solely responsible for all aspects of your sales and transactions with Cashknock and consequently will have liabilities towards the Cashknock in this regard. In document below “we”, “our”, “us” is used for Cashknock solely. And “you”, “your” is used for Consumers which includes either seller or buyer. And “Device”, “Gadget”, “product” is used for “Phone”, “Tablets”,  “Gaming consoles including play stations”, “smart watches”, “Desktops”, “Laptops”, “Cameras”.</p>
                        <ul>
                            <li>1. You acknowledge and undertake that you will use the website to sell products only of your personal use and not for business purposes in the name of your personal brand or Cashknock, Cashknock will not be held responsible for any such acts carried out by you.</li>
                            <li>2. You certify that you are the legal owner of the gadget that you want to sell.</li>
                            <li>3. All initial quotes are pending our evaluation of your gadget and no binding offer is made until we have had a chance to inspect the gadget. We reserve the right to refuse to offer to purchase any item that you offer to sell for any reason we deem, in our sole discretion, to be sensible. We reserve the right to change our quote at any time.</li>
                            <li>
                                <p>4. You will be provided with quote via our website/app/affiliates AND we, upon inspection of your gadget, agree to pay you that quoted amount, you are legally and contractually bound to sell us the gadget for the price quoted via the website.</p>
                            </li>
                            <li>5. You will be provided with quote via our website/app as per the description given by you of the concerned gadget and upon inspection if your gadget is found to be:-
                                <ul>
                                    <li>a. Different model than originally quoted,</li>
                                    <li>b. Has any missing parts,</li>
                                    <li>c. Found in a different condition than stated,</li>
                                    <li>d. Disputed ownership of title. We, in instances mentioned heretofore and beyond, reserve the right to change our offer of the quoted price.</li>
                                </ul>
                            </li>
                            <li>6. All gadgets sold must compulsorily be attached with the following documents:
                                <ul>
                                    <li>a. self-attested ID-proof (government approved) of owner of old gadget;</li>
                                    <li>b. self-attested indemnity bond provided by us if required;</li>
                                </ul>
                            </li>
                            <li>7. Lawful Sales Only. You must own the right, title and all legal interest in the gadget or other articles you sell us. Your sale and/or, shipment of any such article must not violate any law, regulation or statute of any jurisdiction. You may not unlawfully transfer or encumber any intellectual property, trademark, copyright, patent, software, license or other legal right or restriction via your shipping or selling of the picked-up article. The article you sell must be free of all legal restrictions that would affect the value of the article, restrict your legal right to transfer ownership of the article (including the article itself, software’s present on the article, or hardware on or inside the article). You must refrain from violating any export laws or restrictions. The article you sell (including all related materials, software and add on hardware) may not be counterfeited, stolen, or contain harmful or offensive content of any nature. You agree to indemnify Cashknock, that Cashknock will not be held under any legal liability arising out of
                                <ul>
                                    <li>a. your access to or use of Services</li>
                                    <li>b. your violation of these Terms &amp; Conditions</li>
                                </ul>
                            </li>
                            <li>8. It is also solely your responsibility to wipe, clean or delete data on your Gadget being sold to Cashknock. You confirm that all the data in the said device will be erased before handing it over. You also confirm that in spite of erasing the data manually/electronically, if any data still accessible due any technical reason, Cashknock shall not be responsible for the same and you will not approach Cashknock for any retrieval of the data or violation of privacy.</li>
                            <li>9. You understand once a device is sold by you via Cashknock, in no scenario that this device be returned back to you.</li>
                            <li>12. The estimated delivery or pickup time provided by Cashknock is not of binding nature. We have the liberty to reschedule the pickup time depending on certain conditions or availability of agent.</li>
                            <li>13. We will not be held for any acts of the pickup agent, any illegal/offensive acts such as “theft, loss of gadget” done by the pickup agent will be considered as an act done under his personal capacity. Cashknock will not be held liable for any such acts.</li>
                            <li>14. We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.</li>
                            <li>14. Payment will be made to the only account provided by you via electronic mode only either through UPI, bank transfer, IMPS or NEFT. You shall not dispute the mode of payment.</li>
                            <li>15. We will not be held liable for any delay or failure of payment arising from any technical issue with bank either from you end. Under such scenario payments will be guided by the norms of the banks and time will be calculated asper bank norms.</li>
                            <li>15. We reserve the right to modify this agreement at any time without giving you prior notice. Your use of our website/app, any of our tools and services, following any such modification constitutes your agreement to follow and be bound by the Agreement as modified. Terms and conditions modifying the Agreement are effective immediately upon publication.</li>
                            <li>16. You acknowledge and undertake that you are accessing the services on the website and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the website.</li>
                            <li>17. Cashknock reserves the right to cancel any transaction that is deemed suspicious or fraudulent.</li>
                        </ul>
                        <h4>A. Terms and Conditions for Cashknock services:</h4>
                        <p class="MuiTypography-root MuiTypography-body1 css-9l3uo3">By using it, you are agreeing to these Terms &amp; Conditions mentioned here under. Please read them carefully. “Cashknock” owns and operates the website “Cashknock.com” having its registered address at Y-55, Sector 12, Noida-201301, Gutambudh Nagar, U.P.  The mobile touch versions, App on Google Play store and Apple App store and/or any site (s) we have now or in the future that reference these Terms &amp; Conditions “Cashknock”, provides a marketplace and platform for consumers and organizations to sell used, old mobile phones and other Devices. Cashknock is a platform, whether provided through the Site or through other means, are collectively referred to as “Services”. If you do not agree with any part of these Terms &amp; Conditions, you must not use the Site or Services. Your continuous use of the Site or Services will constitute your acceptance of these Terms &amp; Conditions, as they may be modified by us from time to time, with or without notice to you. Please check this page regularly for updates to the Cashknock Terms &amp; Conditions.</p>
                        <ol>
                            <li>
                                <strong>1. Cashknock is an online platform</strong>
                                Cashknock is a platform to allow users, subject to compliance with Cashknock’s policies, to sell certain goods. Cashknock will be directly involved in the transaction between user and cashknowk. You should be solely liable/responsible for all any fraudulent or wrong transactions made in any wrong bank account no. or due to any clerical error on your end.</li>
                            <li>
                                <strong>2. Permitted Use and Compliance with Laws</strong>
                                <p>Cashknock authorizes you to access, view and use the Site content and software solely to the extent necessary for you to use the Services. You may not remove any copyright, trademark or other proprietary notices that have been placed on the Cashknock’s Property. You may not engage in systematic retrieval of data or other content from the Cashknock’s Property. Except as expressly permitted by these Terms &amp; Conditions, any modification, reproduction, redistribution, </p>
                                <p>republication, uploading, posting, advertising, transmitting, distributing or otherwise exploiting in any way the Cashknock’s Property, or any portion of the Cashknock’s Property, is strictly prohibited without the prior written permission of Cashknock.</p>
                                <p>You agree that you will comply with all applicable laws, regulations and ordinances relating to the Site and Services, the Cashknock’s Property or your use of them, and that in using the Site and Services you will not engage in any conduct that restricts or inhibits any other person from using or enjoying the Services.</p>
                            </li>
                            <li>
                                <strong>3. Copyright and trademarks</strong>
                                <p>Except as otherwise indicated, all materials in the Site, including, but not limited to text, software, photos, video, graphics, music, sound, the Cashknock’s Logo, and other Cashknock’s trademarks and service marks and the entire contents of the Site are solely property of Cashknock, and are protected by Indian copyright and trademark laws, all rights reserved. Any violation of these restrictions may result in a copyright, trademark, or other intellectual property right infringement that may subject you to civil and/or criminal penalties.</p>
                                <p>Other marks on the site such as “Brand Logo”, “ Brand names” “ images of certain brands and Gadgets” are not owned by Cashknock may be under license from the trademark owner thereof, or may be the property of their respective owners. The information and materials provided in the Site and through the Services are intended for general reference only, and may not describe all of the terms, conditions, and exceptions applicable to Cashknock’s Services.</p>
                            </li>
                            <li>
                                <strong>5. Privacy and OTP</strong>
                                <p>Cashknock values and protects the privacy of your information. Please review the Cashknock’s privacy policy, as it contains important information relating to your use of the Site and Services. You agree that you will provide your mobile number and get an OTP that you need to enter in order to get registered for use of service. If you get any such OTP without availing any services by us you must notify Cashknock immediately if you believe that a third party is trying to use your data, or if you believe that any unauthorized access or use may occur or has occurred. For your protection, if Cashknock believes that any unauthorized access may occur or has occurred, Cashknock may terminate access without prior notice to you. You also agree that Cashknock is permitted to act upon any instructions received such instructions as authorized by you.</p>
                            </li>
                            <li>
                                <strong>9. Indemnity</strong>
                                Customer agrees to indemnify and hold Cashknock, our Executives, directors, employees, agents and representative of each of them harmless, including costs, liabilities and legal fees, from any claims or demand made by any third party due to or arising out of
                                <ul>
                                    <li>i. your access to or use of Services </li>
                                    <li>ii. your violation of these Terms &amp; Conditions, or</li>
                                    <li>iii. Any infringement by you, or any third party using your Data, of any intellectual property or other right of any person or entity.</li>
                                </ul>
                                <p>Cashknock reserves the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us and you agree to cooperate with our defence of these claims. You agree not to settle any matter without the prior written consent of Cashknock. We will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
                            </li>
                            <li>
                                <strong>10. Applicable Law, Jurisdiction, Compliance</strong>
                                You and Cashknock agree that all matters arising from or relating to the use and operation of the Site and/or the Services will be governed by the substantive laws of Republic of India, without regard to its conflicts of laws principles. You agree that all claims you may have arising from or relating to the operation, use or other exploitation of the Site and/or the Services will be heard and resolved in the courts located in Surajpur, Gutambudh Nagar. Uttar Pradesh-201306. You consent to the personal jurisdiction of such courts over you, stipulate to the fairness and convenience of proceeding in such courts, and covenant not to assert any objection to proceeding in such courts.</li>
                            <li>
                                <strong>11. Miscellaneous Provisions</strong>
                                <p>Any noncompliance or default by you with respect to any of these Terms &amp; Conditions will not impair Cashknock to exercise any of its rights and none of such right shall be construed to be a waiver thereof, and a waiver by us of any of the covenants, conditions or agreements to be performed by you will not be construed to be a waiver of any succeeding breach thereof or of any other covenant, condition or agreement hereof contained. As used in these Terms &amp; Conditions, “including” means “including but not limited to.” If any provision of </p>
                                <p>these Terms &amp; Conditions is found by a court of competent jurisdiction to be invalid or unenforceable, then these Terms &amp; Conditions will remain in full force and effect and will be reformed to be valid and enforceable while reflecting the intent of the parties to the greatest extent permitted by law. Except as otherwise expressly provided, these Terms &amp; conditions set forth the entire agreement between you and Cashknock regarding its subject matter, and supersedes all prior promises, agreements or representations, whether written or oral, regarding such subject matter. You agree that the electronic text of these Terms &amp; Conditions constitutes writing and your assent to the terms and conditions hereof constitutes a “signing” for all purposes.</p>
                            </li>
                            <li>
                                <strong>12. Information Collection, Use, and Sharing</strong>
                                <p>By submitting any Cashknock contact form, you understand that you may be contacted by a Cashknock representatives. You may be contacted via telephone, email, text, or pre-recorded message regarding Cashknock’s programs, offers, events, announcements, and marketing offers from Cashknock. If you respond to any such form, survey etc. where you provide your contact number. By providing us your phone number, you grant Cashknock’s permission for the use of text messages, pre-recorded voice or and/or automatic telephone dialling systems to contact you and/or deliver Cashknock related information, offers or announcements.</p>
                                <p>You also agree that Cashknock may attempt to contact you via any telephone number you provide, even if it is a mobile telephone number which may result in charges to you. Once you subscribe to Cashknock’s email and messaging services you won’t be able to unsubscribe it. In future there be an option which will communicated to you.</p>
                                <p>You understand that providing description of your device or gadget includes specifying the condition of your device including the IMEI of your device. Cashknock will collect this data in relation to conducting the assessment of the quality parameters of your device. In no matter will your private information and data on the device will be accessed for any other purpose and that such information shall not be downloaded by the Company or used in any other manner.</p>
                            </li>
                        </ol>
                        <h4>B. Cookie Policy</h4>
                        <p class="MuiTypography-root MuiTypography-body1 css-9l3uo3">Cashknock uses cookies at their website https://Cashknock.com to distinguish you from other users of this Website. This helps us to provide you with a good experience when you browse our Website and also allows us to improve our Website. By using this Website, you consent to our use of cookies. Although it will not usually appear on subsequent visits, you may withdraw your consent at any time by following the instructions below, including by changing your browser settings so that cookies from this Website cannot be placed on your device. Please note that some of the services on the Website will not function so well if cookies are disabled. After your initial visit to the Website we may change the cookies we use. This cookies policy will always allow you to know who is placing cookies, for what purpose and give you the means to disable them so you should check it from time to time.</p>
                        <h4>What is a Cookie?</h4>
                        <p class="MuiTypography-root MuiTypography-body1 css-9l3uo3">A cookie is a small file of letters and numbers that are sent to and stored on your computer, smartphone or other device for accessing the internet, whenever you visit a site. Cookies are useful because they allow a website to recognize a user’s device. We use cookies for a variety of reasons, such as letting you navigate between pages efficiently, remembering your preferences and generally improving the user experience. Session cookies are deleted automatically when you close your browser and persistent cookies remain on your device after the browser is closed (for example to remember your user preferences when you return to the site).</p>
                        <h4>How and Why the Website Uses Cookies</h4>
                        <p class="MuiTypography-root MuiTypography-body1 css-9l3uo3">The cookies we use are completely safe. In fact, many of them are used purely to provide important security features such as protecting your data. Overall, cookies help us provide you with a better Website and service, by enabling us to monitor which pages you find useful and which you do not. We use cookies to safeguard your privacy when you’re browsing the Website. We also use them to give you the best experience when you visit this Website. We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website and services in order to tailor it to user needs. We only use this information for statistical analysis</p>
                        <div>
                            <p class="MuiTypography-root MuiTypography-body1 css-9l3uo3">purposes and then the data is removed from the system. Finally, we use analytics cookies, including third party analytics cookies, to help us make the Website better for those who visit it regularly. They help us work out what users like and don’t like and how we can improve things for you.</p>
                        </div>
                        <h4>What Cookies Do We Use On Our Website?</h4>
                        <p class="MuiTypography-root MuiTypography-body1 css-9l3uo3">Essential or “strictly necessary” cookies: as you might guess, essential cookies enable essential functionality of the site to work, for example ensuring that you can move between pages on the Website securely. This category of cookies cannot be disabled. For example, session cookies needed to transmit the Website, authentication cookies, and security cookies. We use the following cookies:</p>
                        <div class="table-responsive">
                            <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Cookie Name</th>
                                    <th>What does it do?</th>
                                    <th>Retention Period</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>_ga</p>
                                        <p>_gat</p>
                                        <p>_gid</p>
                                        <p>_hjid</p>
                                        <p>__widgetsettings</p>
                                        <p>_fbp</p>
                                        <p>_hjIncludedInSample</p>
                                        <p>AA003</p>
                                        <p>ads/ga-audiences</p>
                                        <p>GPS</p>
                                        <p>https://cx.atdmt.com/</p>
                                        <p>i/jot/syndication</p>
                                        <p>IDE</p>
                                        <p>VISITOR_INFO1_LIVE</p>
                                        <p>YSC</p></td>
                                    <td><p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website. </p>
                                        <p>Used by Google Analytics to throttle request rate</p>
                                        <p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</p>
                                        <p>Sets a unique ID for the session. This allows the website to obtain data on visitor behaviour for statistical purposes.</p>
                                        <p>Collects data on user behaviour and interaction in order to optimize the website and make advertisement on the website more relevant.</p>
                                        <p>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</p>
                                        <p>Determines if the user’s navigation should be registered in a certain statistical place holder.</p>
                                        <p>Collects information on visitor behaviour on multiple websites. This information is used on the website, in order to optimize the relevance of advertisement.</p>
                                        <p>Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor’s online behaviour across websites.</p>
                                        <p>Registers a unique ID on mobile devices to enable tracking based on geographical GPS location.</p>
                                        <p>Sets a unique ID for the visitor that allows third party advertisers to target the visitor with relevant advertisement. This pairing service is provided by third party advertisement hubs, which facilitates real-time bidding for advertisers.</p>
                                        <p>Used by twitter.Pixel Tracker</p>
                                        <p>Used by Google DoubleClick to register and report the website user’s actions after viewing or clicking one of the advertiser’s ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.</p>
                                        <p>Tries to estimate the users’ bandwidth on pages with integrated YouTube videos.</p>
                                        <p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p></td>
                                    <td><p>2 years</p>
                                        <p>1 day</p>
                                        <p>1 day</p>
                                        <p>Session</p>
                                        <p>Persistant</p>
                                        <p>3 months</p>
                                        <p>Session</p>
                                        <p>3 months</p>
                                        <p>Session</p>
                                        <p>1 days</p>
                                        <p>Session</p>
                                        <p>Session</p>
                                        <p>1 year</p>
                                        <p>179 days</p>
                                        <p>Session</p></td>
                                </tr>
                            </tbody>
                        </table></div>
                        <p>Website functionality cookies: We make use of cookies to provide you with certain functionality. For example, to remember choices you make and to provide you with enhanced and more personalized features. These cookies are not used to transfer your browsing on other sites. We use the following:</p>
                        <p>Social media cookies: These cookies allow users to share our Website or materials on social media sites. Because these cookies are not within our control, you should refer to their respective privacy policies to understand how they work.</p>
                        <p>Use of IP Addresses and Web Logs We may also use your IP address and browser type to help diagnose problems with our server, to administer our Website and to improve the service we offer to you. An IP address is a numeric code that identifies your computer on the internet. Your IP address might also be used to gather broad demographic information. We may perform IP lookups to determine which domain you are coming from (e.g. google.com) to more accurately gauge our users’ demographics.</p>
                        <p>
                            <h4>Keeping your Personal Information Safe</h4>
                            Our cookies do not store personal information such as your name, address, phone number or email in any format that can be read by others. The cookies we use cannot read or search your computer, smartphone or web-enabled device to obtain information about you or your family, or read any material kept on your hard drive. We do use a small number of cookies that store encrypted versions of information where you have asked us to, such as the Login OTP created to identify you as you navigate through pages whilst you are active on the Website.
                        </p>
                        <p>
                            <h4>How to Manage Cookies through the Browser?</h4>
                            The ability to enable, disable or delete cookies can be completed at browser level. In order to do this, follow the instructions provided by your browser (usually located within the “Help”, “Tools” or “Edit” facility). Disabling a cookie or category of cookie does not delete the cookie from your browser; you will need to do this yourself from your browser. If you have disabled one or more cookies, we may still use information collected from cookies prior to your disabled preference being set, however, we will stop using the disabled cookie to collect any further information. Please note that if you activate the setting on your browser that allows you to refuse the setting of all or some cookies (including essential cookies) you may not be able to access all or parts of our Website.
                        </p>
                        <p>
                            <h4>Changes to the Cookies Policy</h4>
                            We may update this cookies policy and we would encourage you to review the policy from time to time to stay informed of how we are using cookies. This cookies policy was last updated on 31st July 2023.
                        </p>
                        <h4>C. Privacy Policy</h4>
                        <p>
                            <h5>Who are we?</h5>
                            “Cashknock” a company having its registered address at Y-55, Sector 12, Noida-201301, Gutambudh Nagar, U.P., India (“we”, “our” or “us”) take the security of your personal data very seriously and are committed to protect and respect the privacy of the users (“you” or your”) of our Cashknock Website and App whatever the Platform is.
                        </p>
                        <p>
                            <h5>What is this privacy notice for?</h5>
                            We may handle your personal data in connection with your use of the Platform. This privacy notice (together with our Terms and Conditions) set out, for the Platform, our collection and sharing practices, the uses to which personal data is put, the ways in which we protect it in accordance with the data protection laws and your privacy rights. Please read it carefully.
                        </p>
                        <p>
                            <h5>These terms applies to Personal Data processed by Cashknock when you:</h5>
                            <ul>
                                <li>• Visit our website (Cashknock.com) and/or any other Cashknock’s website’s link.</li>
                                <li>•  Use, download, access, as applicable, any of our various internet-based offerings, including mobile platforms, software, or applications (collectively, “Services”).</li>
                                <li>• Visit Cashknock’s social media sites or web page</li>
                                <li>•  Receive communications from us, including emails, phone calls, or other electronic messages; or data we collect.</li>
                            </ul>
                        </p>
                        <p>
                            <h5>We collect some information directly from you (for example, via forms you complete or products or Services you access, download, or otherwise obtain. Such information is generally limited to:</h5>
                            <ul>
                                <li>• Name, Contact details, Email ID, IMEI, Device Detail</li>
                                <li>• OTP for success full login to avail the services.</li>
                                <li>• Your communications with Cashknock personnel.</li>
                                <li>• Content you post on our social media sites.</li>
                                <li>• Information you provide on the Website, such as online questionnaires, or feedback forms including the FAQ.</li>
                                <li>• Information you provide when you automatically subscribe by providing mobile number to avail services.</li>
                            </ul>
                        </p>
                        <p>
                            <h5>Such information is generally limited to:</h5>
                            Information regarding usage of the Services or Website via web logs collected automatically and passively using various technologies, which generally will not specifically identify you or other individuals. Examples may include IP addresses, Device details, browser types, domain names, and other anonymous statistical data regarding aggregate usage.
                            <p>Lastly, we also collect Personal Data via other sources such as public records, publicly available sources or internet sites, vendors, data suppliers and service providers, commercially available marketing lists or registries, telephone directories, social network and related media. Such Personal Data collected via these sources is limited to business contact information, such as names, contact information, IP addresses and other social media profiles.</p>
                        </p>
                        <p>
                            <h5>Use of Personal Data</h5>
                            <p>Cashknock uses your Personal Data when: it is necessary to perform our obligations or exercise our contractual rights; we have a legitimate business interest to process such Personal Data; it is necessary to comply with applicable laws or regulations; we have your consent (if required under applicable law) to use your information (where we rely on your consent, you have the right to withdraw consent by contacting us as set forth below).</p>
                            <h5>Such legal reasons are identified for the following common instances:</h5>
                            <p>
                                <h5>To administer our Website and Provide Services:</h5>
                                Personal Data is processed to perform our contractual obligations, where applicable. In instances where we have not entered into a contract with you, such processing is based on our legitimate interest to manage and promote our business, operate and administer the Website, provide and improve the Services, and to provide requested content (Such as quote of the gadget when you request to sell and perform the necessary steps on our website).
                            </p>
                            <p>For User Registration: In accordance with our contractual obligations or legitimate interest to provide Services, when you register to sell you gadget with Cashknock, we process your Personal Data as needed to register and administer your account, provide technical and client support and  verify your identity, and send important account, and Services information.</p>
                            <p>Support Requests: If you request that Cashknock contacts you (for example, for any queries related to products and services), or if you contact us by means available on website, we process your Personal Data to the extent it is necessary for our legitimate interest in fulfilling your requests and communicating with you, or as is required by contractual obligations.</p>
                            <p>For Compliance; Security and Functionality of Website and Services: For our legitimate interests in ensuring adherence to relevant terms, we process your Personal Data to review compliance with the applicable usage. Personal Data is also processed by tracking use of the Website and Services, to perform our contractual obligations, where applicable, to provide a secure and functional experience. In instances where we have not entered into a contract with you, such processing is based on our legitimate interest in promoting the safety and security of the Website, Services, systems and applications and in protecting our rights and the rights of others, whereby we reserve the right to monitor, investigate and report any attempts to breach the security of the Website or Services, verify accounts and activity, and enforce our terms and policies</p>
                            <p>To Improve Website and Services; Developing Offerings: We process your Personal Data to analyse trends, usage, and interactions with the Website and Services to the extent it is necessary for our legitimate interest in improving the Website and Services, developing new offerings, and providing individuals with precise and accurate content and to personalize your experience with our Services. In certain instances, we will process your Personal Data for such development by directly seeking your input. (Such as, through certain feedback surveys etc.)</p>
                            <p>Marketing Communications; Advertisement: It is our legitimate business interest to promote our Website and Services, grow our company, and advertise our offerings (whether via direct marketing or providing personalized content advertisements), and as such we will process your Personal Data by sending certain marketing information and other communications solely as necessary to reflect such legitimate interests, or, if applicable, to the extent you have provided your prior consent.</p>
                            <p>Payments: In the instances where you have provided financial information to us, (Such as Bank Accounts Details, UPI Id’s, Bank transfer Details) Cashknock will process your Personal Data to verify such information and to collect/process payments solely as needed to complete a transaction and perform our contractual obligations. Legal Obligations: We may be required to process Personal Data for legal and compliance reasons we believe to be necessary or appropriate under applicable law. Such processing may be required to: respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities; enforce our terms of service or other terms and conditions; or protect our rights, privacy, safety, or property, or those of other persons. Tracking users’ use of the Platform We use various tools, and collect various information, to assess how you use and interact with the Platform, including information about your visit, such as your IP address, device ID, browser detail and location, connections, or any information that we may collect through the use of cookies, web beacons/gifts, and Behavioural targeting/re-targeting. In most cases this data will be collected automatically. You can find more information about our use of this type of technology in our Cookies Policy.</p>
                            <p>Disclosure of your Information Public Forums: If any part of the Website of Cashknock’s branded social media sites permit public forum posting, the Personal Data you choose to post, share, upload, or make publicly available may be visible to others. You should never post or share any information that is confidential or about others unless you have permission to do so. We may use information you provide to personalize your experience and to make content recommendations. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why. We may also provide a feature allowing you to invite/refer a friend to join Cashknock or avail our services. If you provide a friend’s email address to us for this reason, we will use that email address only for the purpose of sending a onetime invitation to your friend.</p>
                            <p>Using your Data in accordance with Data Protection Law Data Protection Law requires that we meet certain conditions before we are allowed to use your personal data in the manner described in this privacy notice. To use your personal data, we will rely on one of the following conditions, depending on the activities we are carrying out:</p>
                            <p>Consent We may provide you with promotional offers related to our services or products where you have indicated your consent for us to do so (to the extent that we are required to collect consent under Data Protection Laws). We may contact you by mail or email, phone and electronic notifications (where you have agreed to those methods of communication) to provide you with the information on your requested service or product.</p>
                            <p>Legitimate interests It is in our legitimate interests to collect your personal data as it provides us with information that we need to provide our services to you and to make our Platform available. This requires us to carry out a balancing test of our interests in using your personal data (for example, in order to provide you with access to the Platform and ensure no fraudulent activity is promoted through our platform), against the interests you have as a citizen and the rights you have under Data Protection Law (for example, to not have your personal data sold to third party marketing companies without your knowledge). The outcome of this balancing test will determine whether we may use your personal data in the ways described in this privacy notice. We will always act reasonably and give full and proper consideration to your interests in carrying out this balancing test. To provide you with the services that we have agreed to provide to you we are permitted to hold and process some of your personal data because it is necessary to do so in order to provide you the access to, and to enable you to make use of, the platform. Without this personal data, we could not provide you with access to the Platform.</p>
                            <p>Compliance with a legal obligation We are permitted to process your personal data where it is necessary for compliance with our legal obligations. For any kind of legal claims, we are permitted to process your personal data where it is necessary to establish, pursue or defend a legal claim.</p>
                            <p>Substantial Public Interest We are permitted to process your personal data where it is necessary for reasons of substantial public interest, on the basis of Data</p>
                            <p>Protection Laws If we look to use your personal data for any other purpose not covered in this privacy notice, we will let you know about any proposed new purposes before using your personal data in this way.</p>
                            <p>Retention Period Our retention periods for the personal data are based on business needs and legal requirements. We retain personal data for as long as is necessary for the processing purpose(s) for which the information was collected, and any other permissible, related purpose.
                                <h5>We usually keep your information for as long as required to:</h5>
                                <ul>
                                    <li>• For responding to any questions or complaints;</li>
                                    <li>• For showing that we treated you fairly and reasonably ;</li>
                                    <li>• For demonstrating compliance with our regulatory obligations; and/or</li>
                                    <li>• For maintaining records according to rules that apply to us.</li>
                                </ul>
                            </p>
                            <p>We will also keep your information for as long as it is needed for legal, regulatory or technical reasons. We may also keep it for research or statistical purposes. If we do, we will make sure that your privacy is protected and only use it for those purposes.</p>
                            <p>Location of Processing Cashknock is solely located in, and operates from, India. Additionally, we host our Services and process your Personal Data solely in the India. Therefore all the services provided above will process Personal Data within the jurisdiction of India.</p>
                            <p>Services and Minors Cashknock’s Services are solely intended for business. Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872. are not eligible to use the Platform. If you are a minor i.e. under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian. We do not knowingly collect Personal Data from children under the age of 18. If you are a parent or guardian and believe your child has provided us with Personal Data without your consent, please contact us as set forth below.</p>
                            <p>Security of personal information All information you provide to us is stored on our or our servers and accessed and used subject to our security policies and standards. We have provided OTP based access to your account. You should keep this OTP confidential and not share it with anyone else including anyone who works for us. We restrict access to your personal data to our employees.</p>
                            <p>How we may contact you Service communications: We communicate with you regarding the Transaction that you have initiated with us. Marketing communications and editorial newsletters: If we have your permission, we may send you materials we think may interest you, such as Cashknock’s new offers and updates. Depending on your marketing preferences, this may be by email, SMS Updates, WhatsApp including certain other social media platforms.</p>
                            <p>Customer Feedback: Sometimes we may contact you for feedback related to your transactions/experience with Cashknock.</p>
                            <p>Responding to your queries or complaints: If you have raised a query or a complaint with us, we may contact you to answer your query or to resolve your complaint.</p>
                            <p>Updating information Keeping your information accurate and up to date is very important. Inaccurate or incomplete information could impair our ability to deliver relevant services to you. We will use reasonable attempts to ensure that your personal data is accurate. In order to assist us with this, you may notify us of any changes to your personal data by updating your profile on the Platform (App) or by contacting us.
                                <p>Cashknock will not liable for your act of intentionally providing false or misleading information or using another person’s email address or personal data for the purposes of falsely obtaining any products or services through the Platform, such actions by you may lead to termination of access to the Platform and may result in legal action.</p>
                            </p>
                            <p>Modifications to Privacy Notice We may change the content of the Platform and how we use cookies and consequently this privacy notice and our cookies notice and any other document to which is referred as subject to change at any time. If we make material updates to this privacy notice, we will update the date it was last changed and will clearly indicate in the document. Any changes we make to this privacy notice become effective immediately when we post the revised privacy notice on the Platform. We recommend that you review this privacy notice regularly for changes. This privacy notice was last updated on 31july 2023.</p>
                            <p>How to complain Please let us know if you are unhappy with how we have used your personal data or are not satisfied with our handling of any request by you in relation to your rights. You can contact us using the contact details above. You also have the right to complain to the relevant supervisory authority.</p>
                        </p>
                        <h3>D.Indemnity Form</h3>
                        <h5>Indemnity Terms &amp; Conditions</h5>
                        <p>“Cashknock” is a company providing online platform to avail its services, having its registered office at Y-55, Sector 12, Noida-201301, Gutambudh Nagar, U.P., India and is engaged in the business of buying of used/pre-owned electronic gadgets including mobile phones, tablets, Desktops, laptops, Cameras, gaming devices etc.
                            <ul>
                                <li>• I agree and accept the quote provided by Cashknock in exchange of my Gadget.</li>
                                <li>• I agree and confirm that the personal details and ID Proof  will be provided by me at the time of selling any gadget to Cashknock are true and correct in all aspects Policy of Cashknock.</li>
                                <li>. I accept that my submission of personal information is governed by the Privacy</li>
                                <li>.  I agree and confirm that only the following documents would be considered as valid ID proofs – Passport, Aadhar Card, other Government ID’s bearing Photo and Address of me.</li>
                                <li>• I agree and confirm that I am the lawful owner of the gadget and the said device is neither subjected to any third party interests/liability nor is a property of theft etc. I agree that I have the ability to transfer the possession of the gadget being its sole &amp; lawful owner. If any legal action shall arise regarding the ownership of the gadget, Cashknock shall not be liable for the same in any manner whatsoever.</li>
                                <li>• I agree that a valid bill/document will be provided by me to confirm my Law full ownership and title by me at time of selling my gadget to Cashknock.</li>
                                <li>• I agree that if I am unable to provide a valid bill/document at the time of selling my gadget to Cashknock. I am bound to fill an acknowledgment provided by Cashknock along with the valid documents mentioned above to confirm my legal ownership and title of the gadget.</li>
                                <li>• I agree to defend, indemnify and hold harmless, Cashknock, its employees,  agents and their successors and assigns from and against any and all claims, liabilities, damages, losses, costs and expenses, including legal fees, caused by or arising out of claims based upon my actions or inactions like
                                    <ul>
                                        <li>(i) my access to or use of Services,</li>
                                        <li>(ii) my violation of these Terms &amp; Conditions, or</li>
                                        <li>(iii) the infringement by me, or any third party using my account, including but not limited to any warranties, representations or undertakings or in relation to the non-fulfilment of any of its obligations under this agreement or arising out of the Customer’s infringement of any applicable laws, regulations, including but not limited to Intellectual Property Rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights. I agree not to settle any manner without the prior written consent of Cashknock. Cashknock will use reasonable efforts to notify me of any such claim, action or proceeding upon becoming aware of it. I agree that this defence and indemnification obligation will survive termination, modification or expiration of these Terms and your use of the Service and the Platform.</li>
                                    </ul>
                                </li>
                                <li>• I agree to defend, indemnify and hold harmless, Cashknock from any legal actions/ claims for acts done by their pickup agents under their personal capacity such as theft, loss or damage of gadget etc.</li>
                                <li>• Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. I agree that all claims will be heard and resolved in the courts located in Surajpur, Greater Noida. I release Cashknock from all and any losses, claims or damages with respect to the data enclosed or stored therein or on any media used in conjunction with the device.</li>
                            </ul>
                        </p> */}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default Privacy